<a
  class="text-body"
  [routerLink]="
    certificationSessionLiteDTO.isAssessor
      ? [
          '/certification',
          certificationSessionLiteDTO.idCertification,
          string_to_slug(certificationSessionLiteDTO.certificationName | ms),
          'session',
          certificationSessionLiteDTO.idCertificationSession,
          string_to_slug(certificationSessionLiteDTO.certificationName | ms),
        ]
      : [
          '/certification',
          certificationSessionLiteDTO.idCertification,
          string_to_slug(certificationSessionLiteDTO.certificationName | ms),
          'session',
          certificationSessionLiteDTO.idCertificationSession,
          string_to_slug(certificationSessionLiteDTO.certificationSessionName),
          loggedUser.idUser,
          string_to_slug(loggedUser.firstName + ' ' + loggedUser.lastName),
        ]
  "
>
  <div class="bg-white border rounded-3 overflow-hidden p-2 d-flex h-100">
    <div class="row g-1 flex-nowrap align-items-center">
      <div class="col-auto align-icon d-flex align-items-center justify-content-center" style="height: 80px">
        @if (!certificationSessionLiteDTO.certificationImageExtension) {
          <i class="fa-light fa-certificate fa-3x text-tertiary p-4"></i>
        }
        @if (certificationSessionLiteDTO.certificationImageExtension) {
          <img
            class="rounded-2"
            style="height: 80px"
            [img-preloader]="envConfig.configuration.publicFileUrl + 'certification-' + certificationSessionLiteDTO.idCertification + '.png'"
          />
        }
      </div>
      <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">
            <b>{{ certificationSessionLiteDTO.certificationName | ms }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col-auto my-fs-1">
            {{ certificationSessionLiteDTO.certificationSessionName }}
          </div>
        </div>
        <div class="row">
          <div class="col my-fs-1">
            {{
              'global.Du \{\{startDate\}\} au \{\{endDate\}\}'
                | translate
                  : {
                      startDate: certificationSessionLiteDTO.startDate | localizedDate,
                      endDate: certificationSessionLiteDTO.endDate | localizedDate,
                    }
            }}
          </div>
        </div>
      </div>
      <div class="col-auto d-flex align-items-center" *ngIf="certificationSessionLiteDTO.certified">
        <i
          class="fa-light fa-trophy fa-2x text-success px-4"
          style="min-width: 70px; text-align: center"
          ngbTooltip="{{ 'global.Certifié' | translate }}"
          container="body"
        ></i>
      </div>
      <div class="col-auto d-flex align-items-center" *ngIf="certificationSessionLiteDTO.isAssessor">
        <i
          class="fa-light fa-2x fa-user-tie px-4"
          style="min-width: 70px; text-align: center"
          ngbTooltip="{{ 'global.Évaluateur' | translate }}"
          container="body"
        ></i>
      </div>
    </div>
  </div>
</a>
