import { Routes } from '@angular/router';
import {
  AuthGuard,
  AutologComponent,
  LoginComponent,
  LoginSSOComponent,
  LogoutComponent,
  MailAuthComponent,
  NotFoundComponent,
  ResetPasswordComponent,
  ResetPasswordFormComponent,
  ResetPasswordMailSentComponent,
  ResetPasswordPincodeComponent,
  SignupComponent,
  SsoErrorComponent,
  TomAuthComponent,
  UpdateAvailableComponent,
} from 'common';
import { FrontLayoutComponent } from './front-layout/front-layout.component';
import { AskOpinionComponent } from './pages/ask-opinion/ask-opinion.component';
import { InquiryAnswerThanksComponent } from './pages/campaign360/inquiry-answer-thanks/inquiry-answer-thanks.component';
import { ContactComponent } from './pages/contact/contact.component';
import { EquipeKpiComponent } from './pages/ema/equipe-kpi/equipe-kpi.component';
import { GroupActionsComponent } from './pages/group-actions/group-actions.component';
import { GroupEditComponent } from './pages/group-edit/group-edit.component';
import { GroupListComponent } from './pages/group-list/group-list.component';
import { GroupSearchUserComponent } from './pages/group-search-user/group-search-user.component';
import { HomeWrapperComponent } from './pages/home/home-wrapper/home-wrapper.component';
import { JoinSessionComponent } from './pages/join-session/join-session.component';
import { KioskLoginComponent } from './pages/kiosk-login/kiosk-login.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { SearchUserComponent } from './pages/search-user/search-user.component';
import { MyCoachingComponent } from './pages/skillsup/skillsup-coaching/my-coaching/my-coaching.component';
import { StaticPageComponent } from './pages/static-page/static-page.component';
import { UserActionsComponent } from './pages/user-actions/user-actions.component';
import { VisualizeBilanComponent } from './pages/visualize/visualize-bilan/visualize-bilan.component';
import { VisualizeExchangeCardComponent } from './pages/visualize/visualize-exchange-card/visualize-exchange-card.component';
import { VisualizeSurveyComponent } from './pages/visualize/visualize-survey/visualize-survey.component';
import { VisualizeComponent } from './pages/visualize/visualize.component';
import { TestComponent } from './test/test.component';

export const routes: Routes = [
  {
    //en haut pour avoir la priorité sur la route suivante /!\
    path: 'inquiry360/answer/thanks',
    component: InquiryAnswerThanksComponent,
  },

  { path: 'out/gdpr', component: StaticPageComponent },

  {
    path: 'out/formation',
    loadChildren: () => import('./pages/mesure-impact-formation/participant/satisfaction/satisfaction-router.module').then((m) => m.SatisfactionRouterModule),
  },

  {
    path: '',
    component: FrontLayoutComponent,
    canActivateChild: [AuthGuard],
    //resolve: { autolog: AutologResolverService },
    children: [
      { path: '', component: HomeWrapperComponent, data: { title: 'menu.accueil', icon: 'fa-light fa-home' } },
      { path: 'discover', component: StaticPageComponent, data: { title: 'menu.découvrir', icon: 'fa-light fa-play-circle' } },
      { path: 'my-profile', component: MyProfileComponent, data: { title: 'menu.profil', icon: 'fa-light fa-user' } },
      { path: 'user/:idUser/:slug_user', component: UserActionsComponent, data: { title: 'menu.feedback', icon: 'fa fa-user' } },
      { path: 'groups', component: GroupListComponent, data: { title: 'menu.équipes', icon: 'fa fa-users' } },
      { path: 'group/create', component: GroupEditComponent, data: { title: 'menu.équipes', icon: 'fa fa-users' } },
      { path: 'group/:idGroup/:typeGroup/:slug_group', component: GroupActionsComponent, data: { title: 'menu.équipes', icon: 'fa fa-users' } },
      { path: 'group/edit/:idGroup/:typeGroup/:slug_group', component: GroupEditComponent, data: { title: 'menu.équipes', icon: 'fa fa-users' } },
      { path: 'group/edit/:idGroup/:typeGroup/:slug_group/add', component: GroupSearchUserComponent, data: { title: 'menu.équipes', icon: 'fa fa-users' } },
      { path: 'search', component: SearchUserComponent, data: { title: 'menu.recherche', icon: 'fa fa-search' } },
      { path: 'search/:slug', component: SearchUserComponent, data: { title: 'menu.recherche', icon: 'fa fa-search' } },
      { path: 'visualize', component: VisualizeComponent, data: { title: 'menu.visualiser', icon: 'fa-light fa-eye' } },
      { path: 'visualize/fbs', component: VisualizeComponent, data: { title: '', icon: 'fa-light fa-eye' } },
      { path: 'visualize/restitution/:idBilan', component: VisualizeBilanComponent, data: { title: 'menu.restitution', icon: 'fa-light fa-poll-h' } },
      { path: 'visualize/survey/:idCampaign', component: VisualizeSurveyComponent, data: { title: 'menu.enquête', icon: 'fa-light fa-poll' } },
      { path: 'visualize/exchanges', component: VisualizeExchangeCardComponent, data: { title: 'menu.visualiser', icon: 'fa-light fa-eye' } },
      { path: 'group/:idGroup/:typeGroup/:slug_group', component: GroupActionsComponent, data: { title: 'menu.équipes', icon: 'fa fa-users' } },
      { path: 'join', component: JoinSessionComponent, data: { title: 'menu.join', icon: 'fa-light fa-arrow-right-to-bracket' } },
      { path: 'join/:joinCode', component: JoinSessionComponent, data: { title: 'menu.join', icon: 'fa-light fa-arrow-right-to-bracket' } },
      {
        path: 'visualize/survey/:idCampaign/:slug_campaign',
        component: VisualizeSurveyComponent,
        data: { title: 'menu.enquête', icon: 'fa-light fa-poll' },
      },
      {
        path: 'visualize/survey/:idCampaign',
        component: VisualizeSurveyComponent,
        data: { title: 'menu.enquête', icon: 'fa-light fa-poll' },
      },

      {
        path: 'survey',
        data: { title: 'menu.enquête', icon: 'fa-light fa-poll' },
        loadChildren: () => import('./pages/survey/survey.module').then((m) => m.SurveyModule),
      },

      //360

      {
        path: 'inquiry360',
        data: { title: 'menu.campagne360', icon: 'fa-light fa-headset' },
        loadChildren: () => import('./pages/campaign360/campaign360.module').then((m) => m.Campaign360Module),
      },

      //PDI

      {
        path: 'pdi',
        data: { title: 'menu.pdi', icon: 'fa-light fa-graduation-cap' },
        loadChildren: () => import('./pages/pdi/pdi.module').then((m) => m.PdiModule),
      },

      //CAMPAGNE

      {
        path: 'coaching',
        data: { title: 'menu.echanger', icon: 'fa-light fa-comments' },
        loadChildren: () => import('./pages/campaign/campaign180.module').then((m) => m.Campaign180Module),
      },

      //FBS

      { path: 'opinion', component: AskOpinionComponent, data: { title: 'menu.feedback spontané', icon: 'fa-light fa-comments' } },

      {
        path: 'feedback',
        data: { title: 'menu.feedback spontané', icon: 'fa-light fa-comments' },
        loadChildren: () => import('./pages/fbs/fbs.module').then((m) => m.FbsModule),
      },

      {
        path: 'ema',
        data: { title: 'menu.ema', icon: 'fa-light fa-comments-question-check' },
        loadChildren: () => import('./pages/ema/ema.module').then((m) => m.EmaModule),
      },

      { path: 'progress', component: StaticPageComponent, data: { title: 'menu.progresser', icon: 'fa-light fa-chart-line' } },
      { path: 'gdpr', component: StaticPageComponent, data: { title: 'menu.Politique de confidentialité', icon: 'fa-light fa-shield-alt' } },
      { path: 'maj', component: StaticPageComponent, data: { title: 'menu.Mise à jour', icon: 'fa-light fa-wrench' } },
      { path: 'support', component: StaticPageComponent, data: { title: 'menu.support', icon: 'fa-light fa-headset' } },
      { path: 'contact/:slug', component: ContactComponent, data: { title: 'menu.contact', icon: 'fa-light fa-headset' } },

      {
        path: 'certification',
        data: { title: 'menu.certification', icon: 'fa-light fa-certificate' },
        loadChildren: () => import('./pages/certification/certification.module').then((m) => m.CertificationModule),
      },
      {
        path: 'formation',
        data: { title: 'menu.formation', icon: 'fa-light fa-graduation-cap' },
        loadChildren: () => import('./pages/mesure-impact-formation/mif.module').then((m) => m.MifModule),
      },
      {
        path: 'event',
        data: { title: 'menu.Évenement', icon: 'fa-light fa-calendar-star' },
        loadChildren: () => import('./pages/mif-events/mif-events.module').then((m) => m.MifEventsModule),
      },
      { path: 'equipe-kpi', component: EquipeKpiComponent, data: { title: 'menu.equipes KPI', icon: 'fa-light fa-people-group' } },

      {
        path: 'teamboard',
        data: { title: 'menu.dashboard équipe', icon: 'fa-light fa-users-rectangle' },
        loadChildren: () => import('./pages/teamboard/teamboard.module').then((m) => m.TeamboardModule),
      },
      { path: 'test', component: TestComponent, data: { title: 'Test' } },
      //skillsup
      {
        path: 'skillsup/coaching/:idUser/:slug_user',
        component: MyCoachingComponent,
        data: { title: 'menu.specific.skillsup.Mes coachings', icon: 'fa-light fa-people-group' },
      },
    ],
  },

  { path: 'logout', component: LogoutComponent },
  { path: 'login', component: LoginComponent },
  { path: 'mail-auth', component: MailAuthComponent },
  { path: 'sso', component: LoginSSOComponent },
  { path: 'sso/:id', component: LoginSSOComponent },
  { path: 'sso-error/:error/:email', component: SsoErrorComponent },
  { path: 'sso-error/:error', component: SsoErrorComponent },
  { path: 'sso-error', component: SsoErrorComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'reset-password-pincode', component: ResetPasswordPincodeComponent },
  { path: 'reset-password/confirm', component: ResetPasswordMailSentComponent },
  { path: 'reset-password/:token', component: ResetPasswordFormComponent },
  { path: 'test', component: TestComponent },
  { path: 'kiosk', component: KioskLoginComponent },
  {
    path: 'autolog',
    component: AutologComponent,
  },
  {
    path: 'tom-auth',
    component: TomAuthComponent,
  },
  { path: 'update', component: UpdateAvailableComponent },
  { path: '**', component: NotFoundComponent },
];
